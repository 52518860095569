<template>
  <div>
    <b-modal
      id="modal-delete-participant"
      hide-footer
      hide-header
      :centered="true"
    >
      <div class="px-1 py-2">
        <div class="d-flex justify-center">
          <img
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
            alt=""
            srcset=""
          >

        </div>
        <div class="mb-2 mt-2">
          <div class="font-bold text-black text-center">
            Apakah kamu yakin ingin menghapus peserta ini ?
          </div>
        </div>
        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              block
              @click="$emit('submit') && $bvModal.hide('modal-delete-participant')"
            >
              Hapus
            </b-button>
          </b-col>
          <b-col>
            <b-button
              block
              variant="outline-primary"
              @click="$bvModal.hide('modal-delete-participant')"
            >
              Kembali
            </b-button>
          </b-col>

        </b-row>

        <!-- </div> -->
      </div>
    </b-modal>
  </div>
</template>
<script>

export default {

  props: {
    // vmodelmodal: {
    //   type: String,
    //   default: '',
    // },

  },
  setup() {

  },
}
</script>
