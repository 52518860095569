<template>
  <b-modal
    id="modal-view-image"
    hide-footer
    hide-header
    :centered="true"
  >
    <div>
      <b-img
        :src="dataModal.poster"
        fluid
        center
        alt="Responsive image"
      />
    </div>
    <div class="mt-1">
      <span class="font-semibold text-black">{{ dataModal.event_title }}</span> <span>: {{ dataModal.event_sub_title }}</span>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    dataModal: {
      type: Object,
      default: () => {},
    },
  },
}

</script>
