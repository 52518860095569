<template>
  <b-card>
    <b-row>
      <b-col>
        <div
          class="text-black text-2xl font-semibold
          d-flex"
          style="align-items: center"
        >
          <img
            src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
            alt=""
            srcset=""
            class="mr-1"
            style="cursor: pointer;"
            @click="handleBack"
          >
          Detail Event
        </div>
      </b-col>
    </b-row>
    <div class="lg:p-2">
      <b-row
        class="mt-7 mb-1"
        align-h="start"
      >
        <b-col
          cols="4"
          lg="2"
        >
          <div class="text-[#222222]">
            Poster
          </div>
        </b-col>
        <b-col
          class="text-[#222222] d-flex"
        >
          : <div
            v-b-modal.modal-view-image
            class="cursor-pointer ml-2 d-grid justify-content-center"
            @mouseover="mouseOver"
            @mouseleave="mouseLeave"
          > <div
              v-if="state.hover"
              class="cursor-pointer d-grid justify-content-center items-center  bg-black"
              style=" margin-bottom: -73px; position: relative;width: 120px; height: 74px; opacity: 0.7; align-items: center; display: grid; justify-items: center;"
              @click="viewImage(state.dataEvent)"
            >
              <div class="text-center">
                <b-img
                  fluid
                  center
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/eye.svg"
                  style="width: 16px;"
                />
                <p
                  class="text-white text-center text-sm"
                >
                  Lihat Gambar
                </p>
              </div>
            </div>
            <b-img
              :src="state.dataEvent.poster"
              fluid
              alt="poster-image"
              style="width: 120px; height: 74px; object-fit: cover;"
            />
          </div>

        </b-col>
      </b-row>
      <b-row
        align-h="start"
        class="mb-1"
      >
        <b-col
          cols="4"
          lg="2"
        >
          <div class="text-[#222222] flex items-center">
            Judul Event
          </div>
        </b-col>
        <b-col
          class="text-[#222222] d-flex"
        >
          : <div class="ml-2">
            {{ state.dataEvent.event_title }}
          </div>
        </b-col>
      </b-row>
      <b-row
        align-h="start"
        class="mb-1"
      >
        <b-col
          cols="4"
          lg="2"
        >
          <div class="text-[#222222]">
            Sub-Judul Event
          </div>
        </b-col>
        <b-col
          class="text-[#222222] d-flex"
        >
          : <div class="ml-2">
            {{ state.dataEvent.event_sub_title }}
          </div>
        </b-col>
      </b-row>
      <b-row
        class="mt-7 mb-1"
        align-h="start"
      >
        <b-col
          cols="4"
          lg="2"
        >
          <div class="text-[#222222]">
            Type Event
          </div>
        </b-col>
        <b-col
          class="text-[#222222] d-flex"
        >
          : <div class="ml-2">
            {{ state.dataEvent.event_type }}
          </div>
        </b-col>
      </b-row>
      <b-row
        class="mt-7 mb-1"
        align-h="start"
      >
        <b-col
          cols="4"
          lg="2"
        >
          <div class="text-[#222222]">
            Narasumber
          </div>
        </b-col>
        <b-col
          class="text-[#222222] d-flex"
        >
          :
          <div class="ml-2">
            <span
              v-for="(options, index) in state.dataNarasumber"
              :key="index"
            >
              {{ options.name }} ({{ options.position }})
              <span v-if="index < state.dataNarasumber.length - 1">, </span>
            </span>
          </div>
        </b-col>
      </b-row>
      <b-row
        align-h="start"
        class="mb-1"
      >
        <b-col
          cols="4"
          lg="2"
        >
          <div class="text-[#222222]">
            Jumlah Peserta
          </div>
        </b-col>
        <b-col

          class="text-[#222222] d-flex"
        >
          : <div class="ml-2">
            {{ state.dataEvent.total_attendance }} / {{ state.dataEvent.max_attendance }}
          </div>
        </b-col>
      </b-row>
      <b-row
        class="mb-1"
        align-h="start"
      >
        <b-col
          cols="4"
          lg="2"
        >
          <div class="text-[#222222]">
            Harga
          </div>
        </b-col>
        <b-col
          class="text-[#222222] d-flex"
        >
          : <div class="ml-2">
            {{ formatRp(state.dataEvent.event_price) }}
          </div>
        </b-col>
      </b-row>
      <b-row
        align-h="start"
        class="mb-1"
      >
        <b-col
          cols="4"
          lg="2"
        >
          <div class="text-[#222222]">
            Diselenggarakan
          </div>
        </b-col>
        <b-col
          class="text-[#222222] d-flex"
        >
          : <div class="ml-2">
            {{ state.dataEvent.event_status }}
          </div>
        </b-col>
      </b-row>

      <b-row
        class="mb-1"
        align-h="start"
      >
        <b-col
          cols="4"
          lg="2"
        >
          <div class="text-[#222222]">
            Waktu
          </div>
        </b-col>
        <b-col
          class="text-[#222222] d-flex"
        >
          : <div class="ml-2">
            {{ DAY_MONTH_YEAR(state.dataEvent.event_date_start) }}
          </div>
        </b-col>
      </b-row>
      <b-row
        align-h="start"
        class="mb-1"
      >
        <b-col
          cols="4"
          lg="2"
        >
          <div class="text-[#222222]">
            Lokasi
          </div>
        </b-col>
        <b-col
          class="text-[#222222] d-flex"
        >
          : <div class="ml-2">
            {{ state.dataEvent.event_location }}
          </div>
        </b-col>
      </b-row>
      <b-row
        align-h="start"
        class="mb-1"
      >
        <b-col
          cols="4"
          lg="2"
        >
          <div class="text-[#222222]">
            Link Landing Page
          </div>
        </b-col>
        <b-col
          class="text-[#222222] d-flex"
        >
          :  <a
            class="ml-2"
            style="color: blue;"
            @click="goToEvent(state.dataEvent.link_landingpage)"
          >{{ state.dataEvent.link_landingpage }}</a>
        </b-col>
      </b-row>
      <b-row
        align-h="start"
        class="mb-1"
      >
        <b-col
          cols="4"
          lg="2"
        >
          <div class="text-[#222222]">
            Link Form
          </div>
        </b-col>
        <b-col
          class="text-[#222222] d-flex"
        >
          :  <a
            v-if="state.dataEvent.link_form"
            class="ml-2"
            style="color: blue;"
            @click="goToEventForm(state.dataEvent.link_form)"
          >{{ state.urlKomclass }}{{ state.dataEvent.link_form }}</a>
        </b-col>
      </b-row>
      <b-row
        align-h="start"
        class="mb-1"
      >
        <b-col
          cols="4"
          lg="2"
        >
          <div class="text-[#222222]">
            Deskripsi
          </div>
        </b-col>
        <b-col
          class="text-[#222222] d-flex"
        >
          : <div class="ml-2">
            {{ state.dataEvent.description }}
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col class="d-flex justify-end">
        <b-button
          variant="primary"
          :disabled="(state.dataEvent.total_attendance === state.dataEvent.max_attendance) ? true : false"
          @click="addParticipant"
        >
          Tambah Peserta
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <BOverlay
          id="infinite-list"
          variant="light"
          :show="state.loading"
          spinner-variant="primary"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <BTable
            :items="state.items"
            :fields="state.fields"
            show-empty
            empty-text="Belum ada peserta yang terdaftar"
            responsive
            class="mt-1"
          >
            <template #cell(no)="data">
              <div>
                {{ data.index + 1 }}
              </div>
            </template>
            <template #cell(name)="data">
              <div class="text-lg">
                {{ data.item.name }}
              </div>
              <div>
                <p
                  class="text-[#828282]"
                  style="font-size: small;"
                >
                  {{ data.item.email }}
                </p>
              </div>
            </template>
            <template #cell(handphone)="data">
              <div class="d-flex items-center gap-4">
                <div
                  style="cursor: pointer;"
                  class=""
                  @click="openWa(data.item.phone)"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
                    alt=""
                    srcset=""
                  >
                </div>
                <div>
                  0{{ data.item.phone }}
                </div>
              </div>
            </template>
            <template #cell(city)="data">
              <div>
                {{ data.item.city }}
              </div>
            </template>
            <template #cell(product)="data">
              <div>
                <div v-if="!data.item.product.komship && !data.item.product.komtim && !data.item.product.kompack & !data.item.product.komcard && !data.item.product.boostr && !data.item.product.komclass && !data.item.product.pendamping_umkm">
                  <p
                    class="text-[#828282]"
                    style="font-size: small;"
                  >
                    Belum ada produk yang terdaftar
                  </p>
                </div>
                <div
                  v-else
                  class="d-flex container-img"
                >
                  <div
                    v-if="data.item.product.komship"
                    class="w-[3rem] h-[3rem] opacit"
                  >
                    <img

                      src="https://storage.googleapis.com/komerce/assets/logo/Product-Komship.svg"
                      alt="logo-komship"
                      class="mb-[-6px]"
                    >
                  </div>
                  <div
                    v-if="data.item.product.komtim"
                    class="w-[3rem] h-[3rem] opacit"
                  >
                    <img

                      src="https://storage.googleapis.com/komerce/assets/logo/logo_komtim.svg"
                      alt="logo-komtim"
                    >
                  </div>
                  <div
                    v-if="data.item.product.kompack"
                    class="w-[3rem] h-[3rem] opacit"
                  >
                    <img

                      src="https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg"
                      alt="logo-kompack"
                    >
                  </div>
                  <div
                    v-if="data.item.product.komcard"
                    class="w-[3rem] h-[3rem] opacit"
                  >

                    <img

                      src="https://storage.googleapis.com/komerce/assets/logo/Product-Komcards.svg"
                      alt="logo-komcard"
                    >
                  </div>

                  <div
                    v-if="data.item.product.boostr"
                    class="w-[3rem] h-[3rem] opacit"
                  >
                    <img

                      src="https://storage.googleapis.com/komerce/assets/LP-Komerce/komplace.svg"
                      alt="logo-boostr"
                      class="h-6"
                    >
                  </div>
                  <div class="w-[3rem] h-[3rem] opacit">
                    <img
                      v-if="data.item.product.pendamping_umkm"
                      src="https://storage.googleapis.com/komerce/assets/logo/Product-Pendamping%20UMKM.svg"
                      alt="logo-p-umkm"
                      class="h-6"
                    >
                  </div>
                </div>
              </div>
            </template>
            <template #cell(action)="data">
              <div
                class="d-flex"
                style="gap: 1rem; min-width: 20px !important "
              >
                <div
                  style="cursor: pointer;"
                  @click="$bvModal.show('modal-delete-participant',handleDeleteParticipant(data.item.id))"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg"
                    alt="logo-eye"
                  >
                </div>
                <div
                  style="cursor: pointer;"
                  @click="handleEdit(data.item)"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/icons/edit-yellow.svg"
                    alt="logo-edit"
                    srcset=""
                  >
                </div>
              </div>

            </template>
          </BTable>
        </BOverlay>
      </b-col>
    </b-row>
    <modal-delete-participant
      v-model="state.modalShow"
      @submit="handlesubmitDeleteParticipant"
    />
    <modalViewImageVue :data-modal="state.dataModal" />
  </b-card>
</template>

<script>
import { reactive, onMounted, ref } from '@vue/composition-api'
import router from '@/router'
import { useRouter } from '@/@core/utils/utils'
import { newAxiosIns } from '@/libs/axios'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import { columnsTableParticipant, toastError, toastSuccess } from '../config'
import modalDeleteParticipant from './modalDeleteParticipant.vue'
import modalViewImageVue from './ModalViewImage.vue'

export default {
  components: {
    modalDeleteParticipant,
    modalViewImageVue,
  },
  setup() {
    const route = useRouter()
    const state = reactive({
      dataModal: {},
      hover: false,
      id: route.route.value.query.id,
      url: '',
      idParticipant: '',
      loading: false,
      dataEvent: '',
      fields: columnsTableParticipant,
      items: [],
      modalShow: false,
      dataNarasumber: '',
      urlKomclass: process.env.VUE_APP_BASE_URL_EVENT_KOMCLASS,
    })

    async function getDataEvent() {
      state.loading = true
      const url = `/komclass/api/v1/event/${state.id}`
      const { data } = await newAxiosIns.get(url)
      state.items = data.data.attendance
      state.dataEvent = data.data.event_detail
      state.dataNarasumber = data.data.performers
      state.loading = false
    }

    function goToEvent(event) {
      let link = event
      if (!link.startsWith('https://')) {
        link = `https://${link}`
      }
      window.open(link, '_blank')
    }
    function goToEventForm(event) {
      const link = `${process.env.VUE_APP_BASE_URL_EVENT_KOMCLASS}/form?event=${event}`
      window.open(link, '_blank')
    }

    function addParticipant() {
      router.push({
        name: 'data-event-participant-add',
        query: { idEvent: state.id },
      })
    }

    function formatRp(number) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(number)
    }

    function handleDeleteParticipant(id) {
      const idEvent = state.id
      const idParticipant = id
      state.url = `/komclass/api/v1/event/${idEvent}/attendance/${id}/delete`
    }

    async function handlesubmitDeleteParticipant() {
      const urlApi = state.url
      await newAxiosIns.delete(urlApi)
        .then(res => {
          const { data } = res.data
          // eslint-disable-next-line no-console
          toastSuccess('Berhasil menghapus peserta')
          getDataEvent()
        })
        .catch(err => {
          toastError('Ada kesalahan saat menghapus peserta')
        })
    }

    function handleBack() {
      router.back()
    }
    function openWa(phoneNumber) {
      window.open(`https://wa.me/62${phoneNumber}`)
    }

    function handleEdit(data) {
      router.push({
        name: 'data-event-participant-edit',
        query: { idEvent: state.id },
        params: { participant: data },
      })
    }

    const mouseOver = () => {
      state.hover = true
    }

    const mouseLeave = () => {
      state.hover = false
    }

    const viewImage = data => {
      state.dataModal = data
    }

    onMounted(() => {
      getDataEvent()
    })

    return {
      DAY_MONTH_YEAR,
      handleDeleteParticipant,
      route,
      mouseOver,
      state,
      openWa,
      formatRp,
      handlesubmitDeleteParticipant,
      addParticipant,
      handleBack,
      handleEdit,
      mouseLeave,
      viewImage,
      goToEvent,
      goToEventForm,
    }
  },

}
</script>

<style scooped>
.container-img {
  gap: 1px;
}

.opacit {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 2px;
}
.frame {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 74px;
  margin: auto;
}
.frame p {
 position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}
</style>
